#hero {
  margin-top: 10%;
  font-size: 6rem;
}

.gif_table {
  margin-top: 1rem;
  margin-left: 1rem;
}

.search_bar {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
}

.search_input {
  padding: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  width: 30%;
  margin-right: 1rem;
}

.card {
  width: 20rem;
  min-height: 20rem;
  margin: 1rem;
}

.card_image {
  width: 100%;
  height: 15rem;
  object-fit: cover;
}

.card_content {
  margin-top: 1rem;
}

.card_title {
  font-size: 1.5rem;
}

.card_tags {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
}

.badge {
  margin: 0.2rem;
}

.results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
